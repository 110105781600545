.form-control:focus {
    outline: 1px solid rgba(94, 121, 255, 0.5) !important;
    box-shadow: 0 0 0 0.25rem rgba(94, 121, 255, 0.25);
}

/**
Style for modal window copy card
 */
.copy-card-modal #finishModalWizardButton {
    width: 68px;
}

.copy-card-modal #cancelModalWizardButton {
    width: 68px;
}

.copy-card-modal .copy-card-modal-text {
    padding-bottom: 20px;
    padding-right: 50px;
}

.copy-card-modal .modal-body {
    height: 350px;
}

@media screen and (max-width: 390px) {
    .copy-card-modal .modal-body {
        height: auto;
    }
}

.copy-card-modal .modal-dialog {
    width: 95%;
}

.copy-card-modal-block {
    display: flex;
    flex-direction: column;
}

.copy-card-modal-block-top {
    display: flex;
}

.copy-card-modal-text-middle {
    padding: 10px 0 10px 35%;
    color: #818A91;
}

.copy-card-text-align {
    text-align: end;
}

.copy-card-text-align span {
    padding-bottom: 10px;
    padding-top: 10px;
}

.modal-checkbox-position {
    margin-bottom: 3.2rem !important;
}

.copy-card-modal-label-text {
    width: 50%;
}

.pin-new-card {
    top: -5px !important;
}

.copy-card-input {
    width: 155px;
}


/*-- PAGINATOR  --*/

.pagination-block-bottom {
    overflow: hidden;
    padding-bottom: 95px !important;
    overflow-x: auto;
}

.pagination-left-block {
    position: absolute;
    bottom: 35px;
    margin-left: 15px;
}

.pagination-form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.pagination-form-inline input {
    width: 43px;
    margin-left: 5px;
}

.pagination-right-block {
    position: absolute;
    bottom: 20px;
    margin-right: 15px;
}

@media screen and (max-width: 991px) {
    .pagination-right-block {
        margin-left: 15px;
    }

    .pagination-left-block {
        bottom: 70px;
    }
}

@media screen and (min-width: 991px) {
    .pagination-right-block {
        text-align: right;
        right: 0;
    }
}

@media screen and (max-width: 991px) {
    .additional-paginator-wrapper .pagination-right-block {
        margin-left: 0px;
    }
    .pagination-left-block {
        width: 100%;
    }
}

.groups-page .pagination-block .pagination-left-block {
    margin-left: 0px !important;
}

.card-files-wrapper .pagination-block-bottom {
    overflow: inherit !important;
}

/* Activity - Action menu */

.additional-action-menu-style {
    width: 115px !important;
  padding: 3px 5px 3px 10px !important;
}

/* Inputs - Border radius */
.multiselect__tags {
  border-radius: 3px;
}

.display-time {
  border-radius: 3px;
}

.filterAssigned input {
  border-radius: 3px !important;
}

.dropdown-menu-child input {
  border-radius: 3px !important;
}

.form-control-search-address {
  height: 32px;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  box-sizing: border-box;
}

/* Charts tooltip style */

.chart-wrapper div.google-visualization-tooltip {
    border-radius: 10px 10px 10px 0;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(27, 30, 56, 0.25);
    color: #1C1F39;
    font-family: "Nunito Sans";
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding: 0;
    margin-left: -20px !important;
    margin-top: 3px !important;
}

.chart-wrapper div.google-visualization-tooltip > ul > li > span {
    font-family: "Nunito Sans" !important;
    font-size: 14px !important;
}

.chart-wrapper div.google-visualization-tooltip > ul > li:first-child {
    display: none !important;
}

.chart-wrapper .google-visualization-tooltip-item {
    margin: 0 !important;
    padding: 0 !important;
}
